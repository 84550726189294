import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/all';
import SplitType from 'split-type'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useCallback, useMemo, useRef, useState } from "react";

import FooterComponent from './FooterComponent';
import { Box, Button, Stack, Typography, LinearProgress, Grid } from '@mui/material';


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';




gsap.registerPlugin(ScrollTrigger);

const HomeComponent = ({ clickedItem, clickedItem2 }) => {


    // console.log('Clicked Item:', clickedItem);
    // console.log('Clicked Item2:', clickedItem2);

    const aboutRef = useRef(null);
    const homeRef = useRef(null);
    const resumeRef = useRef(null);
    const expRef = useRef(null);
    const projectRef = useRef(null)
    const publicationRef = useRef(null);
    const skillRef = useRef(null);
    const contactRef = useRef(null);


    useEffect(() => {
        if (clickedItem === "About" && aboutRef.current) {
            aboutRef.current.scrollIntoView({ behavior: "smooth" });
        }
        if (clickedItem === "Home" && homeRef.current) {
            homeRef.current.scrollIntoView({ behavior: "smooth" });
        }
        if (clickedItem === "Resume" && resumeRef.current) {
            resumeRef.current.scrollIntoView({ behavior: "smooth", });
        }
        if (clickedItem === "Publications" && publicationRef.current) {
            publicationRef.current.scrollIntoView({ behavior: "smooth", top: "-10%" });
        }
        if (clickedItem === "Contact" && contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: "smooth", });
        }
    }, [clickedItem]);


    useEffect(() => {
        if (clickedItem2 === "About" && aboutRef.current) {
            aboutRef.current.scrollIntoView({ behavior: "smooth" });
        }
        if (clickedItem2 === "Home" && homeRef.current) {
            homeRef.current.scrollIntoView({ behavior: "smooth" });
        }
        if (clickedItem2 === "Resume" && resumeRef.current) {
            resumeRef.current.scrollIntoView({ behavior: "smooth", });
        }

        if (clickedItem2 === "Publications" && publicationRef.current) {
            publicationRef.current.scrollIntoView({ behavior: "smooth", top: "-10%" });
        }
        if (clickedItem2 === "Contact" && contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: "smooth", });
        }
    }, [clickedItem2]);

    const percentage = 66;

    const containerRef = useRef(null);


    const [init, setInit] = useState(false);


    useEffect(() => {

        AOS.init();


        const myText = new SplitType('.hero-text', { type: 'chars' })
        const chars = myText.chars;
        // console.log(chars);

        const tl = gsap.timeline();

        tl.fromTo(chars, {
            opacity: 0, // Start from opacity 0
            yPercent: 80,
        }, {
            opacity: 1, // Animate to opacity 1
            yPercent: 0, // Animate yPercent to 0
            stagger: 0.1,
            duration: 0.4,
            ease: "power1.inOut",
        });

        // tl.fromTo(".title4", {
        //     opacity: 0,
        //     y: 200,
        // }, {
        //     opacity: 1,
        //     y: 0,
        //     duration: 0.2,
        //     ease: "power1.easeOut",
        // });

        gsap.fromTo(".about-image img", {
            opacity: 0,
            x: -100,
        }, {
            opacity: 1,
            x: 0,
            duration: 10, // Duration of the fade-in effect
            ease: "power1.out",
        });

    }, [])

    const handleScrollDown = () => {
        if (aboutRef.current) {
            aboutRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }


    const handleRefs = (section) => {
        if (section === "about" && aboutRef.current) {
            aboutRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (section === "experience" && expRef.current) {
            expRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (section === "resume" && resumeRef.current) {
            resumeRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (section === "project" && projectRef.current) {
            projectRef.current.scrollIntoView({ behavior: "smooth", });
        }
        if (section === "skills" && projectRef.current) {
            projectRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [isLesser992, setIsLesser992] = useState(window.innerWidth <= 992);

    useEffect(() => {
        const handleResize = () => {
            setIsLesser992(window.innerWidth < 992);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const ProfessionalLanguage = [
        { name: 'Python', percentage: 85 },
        { name: 'Java', percentage: 65 },
        { name: 'C, C++', percentage: 60 },
        { name: 'Docker & Azure', percentage: 85 },
        { name: 'Protégé-ontologie', percentage: 80 },
        { name: 'RAG & Data scraping', percentage: 85 },
        { name: 'SQL', percentage: 70 },
        { name: 'AI libraries', percentage: 80 },

    ];
    const basicLanguage = [
        { name: 'English', percentage: "Fluent" },
        { name: 'French', percentage: "Above Basic" },
        { name: 'German', percentage: "Basic" },
    ];

    const renderSkills = (title, skills) => (
        <Box sx={{ p: 4, backgroundColor: '#2c3e50', borderRadius: '8px', mb: 4, }}>
            <Typography variant="h5" sx={{ color: '#ecf0f1', mb: 2 }}>{title}</Typography>
            <Grid container spacing={3} >
                {skills.map((skill, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box >
                            <Typography variant="h6" sx={{ color: '#ecf0f1' }} data-aos="fade-in" data-aos-duration="1000">{skill.name}</Typography>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <LinearProgress
                                    sx={{
                                        width: '100%',
                                        height: '10px',
                                        borderRadius: '5px',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#1abc9c',
                                        },
                                    }}
                                    variant="determinate"
                                    value={skill.percentage}
                                />
                                <Typography variant="body2" sx={{ color: '#ecf0f1', textAlign: "start" }}>
                                    {typeof skill.percentage === 'number' ? `` : skill.percentage}
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = '/NapthalineGERARD_Resume.pdf'; // Adjust the path as needed
        link.download = 'NapthalineGERARD_Resume.pdf'; // File name for download
        link.click();
    };

    const [swiperRef, setSwiperRef] = useState(null);


    return (
        <div className="container-2">
            <div className='hero-1' ref={homeRef}>


                <div className='hero-child1'>
                    <div className='hero-image'>
                        {/* <img src={require("../Images/bg-img.png")} /> */}
                        <img src={require("../Images/my-image.png")} />

                    </div>
                    <br />
                    <Stack spacing={1} direction="column" sx={{ textAlign: "center" }}>
                        <h1 className='hero-text' style={{ color: "white" }}>Napthaline GERARD</h1>
                        <h4 className='hero-text dialogue' style={{ color: "white" }}>Innovative ! Passionate ! Productive !</h4>
                        {/* <h1 className='hero-text' style={{ color: "white" }}>AI Developer</h1> */}
                        {/* <h1 className='hero-text' style={{ color: "white" }}>Bio Computing</h1>
                        <h1 className='hero-text' style={{ color: "white" }}> Bio Engineer</h1> */}
                    </Stack>
                </div>

                <br />
                <br />
                <div className='hero-child2' data-aos="fade-in" data-aos-duration="1500">
                    <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "center", }}>
                        <Button onClick={() => handleRefs("about")} variant='h6' sx={{ color: "white", p: 1, borderRadius: "10px", m: 1, cursor: "pointer", fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif` }}>About me</Button>
                        <Button onClick={() => handleRefs("experience")} variant='h6' sx={{ color: "white", p: 1, borderRadius: "10px", m: 1, cursor: "pointer", fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif` }}>Experience</Button>
                        <Button onClick={() => handleRefs("skills")} variant='h6' sx={{ color: "white", p: 1, borderRadius: "10px", m: 1, cursor: "pointer", fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif` }}>Skills</Button>
                        <Button onClick={() => handleRefs("project")} variant='h6' sx={{ color: "white", p: 1, borderRadius: "10px", m: 1, cursor: "pointer", fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif` }}>Projects</Button>
                    </Box>
                </div>
            </div>
            <div style={{ height: "20px" }}>

            </div>

            {/* A B O U T */}

            <div className='p-child-container2' ref={aboutRef} >
                <h3 style={{ fontSize: "xx-larger", fontWeight: "900", color: "white", fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif`, letterSpacing: "5px" }}>About Me</h3>

                <Stack spacing={2} direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}>
                    <Box sx={{ width: { md: "50%" } }}>
                        <div className='about-image'>
                            <img src={require("../Images/bg-img.png")} />
                        </div>
                    </Box>
                    <br />
                    <Box sx={{ width: { md: "50%", overflowX: "hidden" } }}>

                        <h5 data-aos="fade-left" data-aos-duration="1500" style={{ textAlign: "justify", textIndent: "0px", }}>I’m Napthaline GERARD, a Research Scholar at École Centrale de Lille and an AI Developer with a strong passion for innovation through continuous learning and exploration. My expertise lies in utilizing advanced technologies to tackle complex challenges and create impactful solutions across diverse domains. I have developed AI modules and computational algorithms for healthcare applications, but I’m always eager to explore new fields where AI can make a difference. Driven by a desire to expand my knowledge, I am committed to building intelligent systems that push the boundaries of what’s possible. Thank you for visiting my portfolio, and I look forward to connecting with you!</h5>

                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", p: 2 }}>
                            {/* <a href="/NapthalineGERARD_Resume.pdf" download="NapthalineGERARD_Resume.pdf"> */}

                            <Button sx={{
                                p: { xs: 1, sm: 1, md: 1, lg: 1 }, width: "50%", textAlign: { xs: "center", sm: "center", }, border: "1px solid white", bgcolor: "white", color: "black", '&:hover': {
                                    color: 'white',
                                }
                            }} onClick={handleDownload}
                            > Download CV</Button>
                            {/* </a> */}
                        </Box>


                    </Box>


                </Stack>

            </div >

            {/* R E S U M E */}

            <div className='p-child-container3' ref={expRef}>
                <div className='resume-title-head'>
                    <h3 style={{ fontSize: "xx-larger", fontWeight: "900", color: "white", fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif`, letterSpacing: "5px" }}> My Resume</h3>
                </div>
                <div className='resume-content'>


                    <section id="resume" className="resume">
                        <div className="container">

                            <div className="row">
                                <div className="col-lg-6">

                                    <h3 className="resume-title">Professional Experience</h3>
                                    <div className="resume-item">
                                        <h4 >Ecole Centrale de Lille</h4>
                                        <h5 data-aos="fade-right" data-aos-duration="1500">Doctoral Researcher – Oct 2021 to Sept 2024</h5>
                                        <p><em>France </em></p>
                                        <p>
                                        </p><ul>
                                            <li>Developed Symbolic and Sub-Symbolic AI modules .</li>
                                            <li>Clinical Data analysation using Machine Learning. </li>
                                            <li>Medical Image processing using Neural networks and U-Net.</li>
                                            <li>Integrating different AI modules and Grad-CAM activation function.</li>
                                        </ul>
                                        <p />
                                    </div>
                                    <div className="resume-item">
                                        <h4>CRIStAL Laboratory- OSL team Lille</h4>
                                        <h5 data-aos="fade-right" data-aos-duration="1500">Intern - March 2021 to August 2021</h5>
                                        <p><em>France</em></p>
                                        <p>
                                        </p><ul>
                                            <li>Designing an AI Ontology for Genetic Diseases utilizing Semantic Reasoning in Protégé.</li>
                                            <li>Developed a Machine Learning model for processing Medical Images.
                                            </li>
                                        </ul>
                                        <p />
                                    </div>

                                    <div className="resume-item">
                                        <h4>Mahatma Gandhi Medical College and Research Institute Puducherry
                                        </h4>
                                        <h5 data-aos="fade-right" data-aos-duration="1500">Intern - June 2017 to August 2017</h5>
                                        <p><em>India</em></p>
                                        <p>
                                        </p><ul>
                                            <li>Maintained, calibrated, and tested biomedical equipment in ICU, CCU, OT, Radiology, Simulation Center,and Emergency Medicine.</li>
                                        </ul>
                                        <p />
                                    </div>




                                </div>

                                <div className="col-lg-6">
                                    <h3 className="resume-title">Education</h3>
                                    <div className="resume-item">
                                        <h4 style={{ textTransform: "none" }}>PhD</h4>
                                        <h5 data-aos="fade-right" data-aos-duration="1500">From October 2021 to October 2024</h5>
                                        <p><em> CRIStAL Laboratory - OSL team & Ecole Centrale de
                                            Lille. Lille, France</em></p>
                                        <p>AI module development, Data processing, Data analyzation and Integration of AI modules.</p>
                                        {/* <ul>

                                            <li>
                                                Explainable Hybrid Artificial Intelligence to Predict Pulmonary Tuberculosis
                                                Integrating Ontology and Deep learning.
                                            </li>
                                            <li>Decision support system & Decision making knowledge system.</li>
                                            <li>Deep neural classification & Chest X-ray lesion classification.</li>
                                            <li>Grad-CAM activation function.</li>
                                        </ul> */}
                                    </div>
                                    <div className="resume-item">
                                        <h4>Master - International Biomedical Engineering. </h4>
                                        <h5 data-aos="fade-right" data-aos-duration="1500">From September 2019 to July 2021</h5>
                                        <p><em>Ecole centrale de lille. Lille, France</em></p>
                                        <p>Medical Science, Bio Computation, Medical Imaging, Medical Devices Development, Surgical implants, Signal Processing  and Project management.
                                        </p>
                                        {/* <ul>
                                            <li>
                                                Bionic Hand design in CAD using anatomy principle.
                                            </li>
                                            <li>
                                                Foot prosthesis in CAD.
                                            </li>
                                            <li>
                                                Design of optical pulse Oximeter.
                                            </li>
                                        </ul> */}
                                    </div>
                                    <div className="resume-item">
                                        <h4>B.Tech - Computer science Engineering</h4>
                                        <h5 data-aos="fade-right" data-aos-duration="1500"> From August 2015 to
                                            May 2019</h5>
                                        <p><em>Rajiv Gandhi College of Engineering and
                                            Technology Puducherry, India</em></p>
                                        <p>Study  of computation, algorithms, programming languages, program
                                            design, software, hardware, etc.</p>
                                        {/* <ul>

                                            <li>
                                                SAM (Smart Agriculture Monitoring) system using IOT.
                                            </li>
                                            <li>
                                                Chest Radiographic diagnosis of Pulmonary Tuberculosis using
                                                Image processing technique in a Hospital.
                                            </li>
                                        </ul> */}
                                    </div>

                                    {
                                        isLesser992 &&
                                        <>
                                            <br />

                                            <div className='p-child-container4-languages' ref={skillRef}>
                                                <div className='container4-child1' style={{ justifyContent: "start" }}>
                                                    <h3 style={{ fontSize: "xx-large", fontWeight: "900", color: "white", fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif`, letterSpacing: "5px", padding: "60px" }}>Skills</h3>
                                                </div>
                                                <div className='container4-child2-languages' >
                                                    <Box sx={{ width: '100%', p: 3, display: "grid", backgroundColor: "#2c3e50" }}>
                                                        <Stack direction="column" spacing={2} >
                                                            <em style={{ color: "white" }}> Professional </em>
                                                            {ProfessionalLanguage.map((skill, index) => (
                                                                <Box key={index} >
                                                                    <Typography variant="h6" data-aos="fade-right" data-aos-duration="1000">{skill.name}</Typography>
                                                                    <Typography variant="body2" sx={{ color: '#ecf0f1', textAlign: "right" }}>
                                                                        {/* {`${skill.percentage}%`} */}
                                                                    </Typography>
                                                                    <LinearProgress sx={{ height: "10px", borderRadius: "7px" }} variant="determinate" value={skill.percentage} />
                                                                </Box>
                                                            ))}
                                                        </Stack>
                                                    </Box>
                                                    <br />
                                                    <div className='container4-child1' style={{ justifyContent: "start" }}>
                                                        <h3 style={{ fontSize: "xx-large", fontWeight: "900", color: "white", fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif`, letterSpacing: "5px", padding: "60px" }}>Languages</h3>
                                                    </div>
                                                    <Box sx={{ width: '100%', p: 3, display: "grid", backgroundColor: "#2c3e50" }}>
                                                        <Stack direction="column" spacing={2}>
                                                            <em style={{ color: "white" }}> Basic </em>
                                                            {basicLanguage.map((skill, index) => (
                                                                <Box key={index}>
                                                                    <Typography variant="h6" data-aos="fade-right" data-aos-duration="1000">{skill.name}</Typography>
                                                                    <Typography variant="body2" sx={{ color: '#ecf0f1', textAlign: "end" }}>
                                                                        {`${skill.percentage}`}
                                                                    </Typography>
                                                                    <LinearProgress sx={{ height: "10px", borderRadius: "7px" }} variant="determinate" value={skill.percentage} />
                                                                </Box>
                                                            ))}
                                                            <Box sx={{ visibility: "none", opacity: 0 }}>
                                                                <Typography variant="h6">german</Typography>
                                                                <LinearProgress variant="determinate" value={10} />
                                                            </Box>
                                                        </Stack>
                                                    </Box>
                                                </div>
                                            </div>


                                        </>
                                    }


                                </div>
                            </div>
                        </div>
                    </section>

                    {
                        !isLesser992 &&
                        <>
                            <br />

                            <div className='p-child-container4-languages' >
                                <div className='container4-child1' style={{ justifyContent: "start" }}>
                                    <h3 style={{ fontSize: "xx-larger", fontWeight: "900", color: "white", fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif`, letterSpacing: "5px", padding: "30px" }}>Skills</h3>
                                </div>
                                <div className='container4-child2-languages'>
                                    <Box sx={{ p: 5, minHeight: '100vh', width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                                        {renderSkills('Professional', ProfessionalLanguage)}
                                        <div className='container4-child1' style={{ justifyContent: "start" }}>
                                            <h3 style={{ fontSize: "xx-larger", fontWeight: "900", color: "white", fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif`, letterSpacing: "5px", padding: "30px" }}>Languages</h3>
                                        </div>

                                        <Box sx={{ p: 4, backgroundColor: '#2c3e50', borderRadius: '8px', mb: 4 }}>
                                            <Typography variant="h5" sx={{ color: '#ecf0f1', mb: 2 }}>Professional</Typography>
                                            <Grid container spacing={3} direction="row">
                                                <Grid item xs={12} sm={4}>
                                                    <Stack spacing={2}>

                                                        <Typography variant="h6" sx={{ color: '#ecf0f1' }} data-aos="fade-in" data-aos-duration="1000">English</Typography>
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <LinearProgress
                                                                sx={{
                                                                    width: '200px',
                                                                    height: '10px',
                                                                    borderRadius: '5px',
                                                                    '& .MuiLinearProgress-bar': {
                                                                        backgroundColor: '#1abc9c',
                                                                    },
                                                                }}
                                                                variant="determinate"
                                                                value={80}
                                                            />
                                                            <Typography variant="body2" sx={{ color: '#ecf0f1', textAlign: "start" }}>
                                                                Fluent
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12} sm={4}>
                                                    <Stack spacing={2}>
                                                        <Typography variant="h6" sx={{ color: '#ecf0f1' }} data-aos="fade-in" data-aos-duration="1000"> French</Typography>
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <LinearProgress
                                                                sx={{
                                                                    width: '200px',
                                                                    height: '10px',
                                                                    borderRadius: '5px',
                                                                    '& .MuiLinearProgress-bar': {
                                                                        backgroundColor: '#1abc9c',
                                                                    },
                                                                }}
                                                                variant="determinate"
                                                                value={50}
                                                            />
                                                            <Typography variant="body2" sx={{ color: '#ecf0f1', textAlign: "start" }}>
                                                                Above Basic
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12} sm={4}>
                                                    <Stack spacing={2}>
                                                        <Typography variant="h6" sx={{ color: '#ecf0f1' }} data-aos="fade-in" data-aos-duration="1000">German</Typography>
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <LinearProgress
                                                                sx={{
                                                                    width: '200px',
                                                                    height: '10px',
                                                                    borderRadius: '5px',
                                                                    '& .MuiLinearProgress-bar': {
                                                                        backgroundColor: '#1abc9c',
                                                                    },
                                                                }}
                                                                variant="determinate"
                                                                value={50}
                                                            />
                                                            <Typography variant="body2" sx={{ color: '#ecf0f1', textAlign: "start" }}>
                                                                Basic
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                    </Box>
                                </div>
                            </div>


                        </>
                    }

                </div>
            </div>


            {/* P R O J E C T S*/}

            <div className='experience-slider' ref={projectRef}>
                <Stack direction="column" spacing={3}>
                    <h3 style={{ fontSize: "xx-large", fontWeight: "900", color: "white", fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif`, letterSpacing: "5px", padding: "30px", paddingLeft: "60px" }}>Projects</h3>

                    <Swiper
                        style={{ backgroundColor: "#1d374f", }}
                        onSwiper={setSwiperRef}
                        slidesPerView={3}
                        // centeredSlides={true}
                        spaceBetween={30}
                        pagination={{
                            type: 'bullets',
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"

                        breakpoints={{
                            200: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1440: {
                                slidesPerView: 4,
                                spaceBetween: 40,
                            },
                        }}
                    >

                        {/* 1 */}
                        <SwiperSlide style={{ width: "200px", display: "flex", justifyContent: "space-around", borderRadius: "10px" }}>
                            <Box sx={{ width: "100%", height: "100%" }}>
                                <Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                        <img src="https://imageio.forbes.com/specials-images/imageserve/643016813686d8eafca00875/0x0.jpg?format=jpg&height=600&width=1200&fit=bounds" width="200px" height="100px" style={{ placeSelf: "center", borderRadius: "10px" }} />
                                    </Box>

                                    <Typography variant='h6' sx={{ color: "white", p: 1, textAlign: "center" }} data-aos="fade-in">Explainable Hybrid AI Prediction System

                                    </Typography>

                                    <ol style={{ padding: "20px" }}>
                                        <li style={{ listStyle: "none", marginBottom: "20px", textAlign: "justify" }}>
                                            <Typography variant='p' sx={{ color: "white", fontSize: "medium", textAlign: "justify" }}>Integrating different AI modules to predict early-stage TB cases by combining symbolic AI, which uses rule-based reasoning and knowledge representation, with sub-symbolic AI, such as neural networks, which relies on pattern recognition and data-driven learning.


                                            </Typography>
                                        </li>

                                    </ol>
                                </Box>
                            </Box>
                        </SwiperSlide>

                        {/* 2 */}
                        <SwiperSlide style={{ width: "200px", display: "flex", justifyContent: "space-around", borderRadius: "10px" }}>
                            <Box sx={{ width: "100%", height: "100%" }}>
                                <Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-MhGWDsIxMvF59PkENJh9KoZ4Vof1gXnuog&s" width="200px" height="100px" style={{ placeSelf: "center", borderRadius: "10px" }} />
                                    </Box>

                                    <Typography variant='h6' sx={{ color: "white", p: 1, textAlign: "center" }} data-aos="fade-in"> Deep Neural classification using Chest X-ray

                                    </Typography>

                                    <ol style={{ padding: "20px", textIndent: "0px" }}>
                                        <li style={{ listStyle: "none", marginBottom: "20px", textAlign: "justify", textIndent: "0px" }}>
                                            <Typography variant='p' className='text-justify' sx={{ color: "white", fontSize: "medium", }}>A Convolutional Neural Network based deep neural network model was implemented to classify X-ray images, differentiating between diseased and healthy patients.

                                            </Typography>
                                        </li>

                                    </ol>
                                </Box>
                            </Box>
                        </SwiperSlide>

                        {/* 3 */}
                        <SwiperSlide style={{ width: "200px", display: "flex", justifyContent: "space-around", borderRadius: "10px" }}>
                            <Box sx={{ width: "100%", height: "100%" }}>
                                <Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyNAuFxgjWR9KpYGKE-7O-KYqN0VckSfp7DA&s" width="200px" height="100px" style={{ placeSelf: "center", borderRadius: "10px" }} />
                                    </Box>

                                    <Typography variant='h6' sx={{ color: "white", p: 1, textAlign: "center" }}
                                        data-aos="fade-in" data-aos-duration="1000">Onto Decision Support System
                                    </Typography>

                                    <ol style={{ padding: "20px" }}>
                                        <li style={{ listStyle: "none", marginBottom: "20px", textAlign: "lf", textIndent: "0px" }}>
                                            <Typography variant='p' sx={{ color: "white", fontSize: "medium" }}>Decision Support System for biological tests using an ontology-driven intelligent system, integrate ontologies, reasoning, and logical queries to create a Knowledge Base for decision-making.

                                            </Typography>
                                        </li>

                                    </ol>
                                </Box>
                            </Box>
                        </SwiperSlide>

                        {/* 4 */}
                        <SwiperSlide style={{ width: "200px", display: "flex", justifyContent: "space-around", borderRadius: "10px" }}>
                            <Box sx={{ width: "100%", height: "100%" }}>
                                <Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", p: 2, }}>
                                        <img src="https://www8.garmin.com/manuals/webhelp/GUID-1E5740B3-60A1-4890-B39A-7587060D785A/Shared/GUID-C8E14199-A6F7-4F2C-8572-DB382419DDF0-high.jpg" width="200px" height="100px" style={{ placeSelf: "center", borderRadius: "10px" }} />
                                    </Box>

                                    <Typography variant='h6' sx={{ color: "white", p: 1, textAlign: "center" }} data-aos="fade-in">Optical Pulse Oximeter using Arduino
                                    </Typography>

                                    <ol style={{ padding: "20px" }}>
                                        <li style={{ listStyle: "none", marginBottom: "20px", textAlign: "justify" }}>
                                            <Typography variant='p' sx={{ color: "white", fontSize: "medium", textAlign: "justify" }}> Designed a PPG system with the SFH 7050 to measure blood oxygen levels. The system uses a light source near the skin to detect light intensity changes due to blood flow. A light-sensitive receiver converts these changes into an electrical current, which is filtered, amplified, and analyzed by an Arduino for real-time measurements.

                                            </Typography>
                                        </li>

                                    </ol>
                                </Box>
                            </Box>
                        </SwiperSlide>

                        {/* 5 */}
                        <SwiperSlide style={{ width: "200px", display: "flex", justifyContent: "space-around", borderRadius: "10px" }}>
                            <Box sx={{ width: "100%", height: "100%" }}>
                                <Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                        <img src="https://lvivity.com/wp-content/uploads/2020/04/iot-in-agriculture.jpg" width="200px" height="100px" style={{ placeSelf: "center", borderRadius: "10px" }} />
                                    </Box>

                                    <Typography variant='h6' sx={{ color: "white", p: 1, textAlign: "center" }} data-aos="fade-in">SAM (Smart Agriculture Monitoring) system

                                    </Typography>

                                    <ol style={{ padding: "20px" }}>
                                        <li style={{ listStyle: "none", marginBottom: "20px", textAlign: "justify" }}>
                                            <Typography variant='p' sx={{ color: "white", fontSize: "medium", }}>It is an IoT system built with Arduino for detecting animals and intruders. It uses sensors and connectivity features to monitor and identify movement, sending alerts for animals and unauthorized individuals. Additionally, it includes an optical monitor and calamity check functions.

                                            </Typography>
                                        </li>

                                    </ol>
                                </Box>
                            </Box>
                        </SwiperSlide>
                    </Swiper>

                </Stack>

            </div>


            {/* P O R T F O L I O */}
            <div className='p-child-container4' ref={publicationRef}>
                <div className='container4-child1' >
                    <h3 className='portfolio-title'>Conference & Journal</h3>
                </div>
                <div className='container4-child2'>
                    <ol style={{ padding: "50px", marginLeft: "20px" }}>

                        <Typography variant='body1' data-aos="fade-right" data-aos-duration="1000" sx={{ textTransform: "none" }}>
                            <li>Deep learning system to diagnose Pulmonary Tuberculosis using chest X-ray, ISERD International Conference, Dubai, January 2024.
                            </li>
                        </Typography>
                        <Button
                            sx={{ m: 2 }}
                            variant="contained"
                            color="primary"
                            href="https://drive.google.com/file/d/1_7nK4hL2iLGSs1qW60pM5JKJnvpOeWKq/view?usp=drivesdk"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Click here to read more
                        </Button>

                        <Typography variant='body1' data-aos="fade-right" data-aos-duration="1000">
                            <li>Symbolic Artificial Intelligence to Diagnose Tuberculosis Using Ontology, International Medical conference, Medinfo, Australia, July 2023.
                            </li>
                        </Typography>
                        <Button
                            sx={{ m: 2 }}
                            variant="contained"
                            color="primary"
                            href="https://pubmed.ncbi.nlm.nih.gov/38426879/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Click here to read more
                        </Button>

                        <Typography variant='body1' data-aos="fade-right" data-aos-duration="1000">
                            <li>Chest Radiographic diagnosis of Pulmonary
                                Tuberculosis using Image processing technique in
                                Android Application, Journal of JETIR, India, March 2019.
                            </li>
                        </Typography>
                        <Button
                            sx={{ m: 2 }}
                            variant="contained"
                            color="primary"
                            href="http://www.jetir.org/papers/JETIRAK06013.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Click here to read more
                        </Button>


                    </ol>


                </div>
            </div>

            {/* C O N T A C T */}
            {/* <div className='p-child-container5' ref={contactRef}>
                <div className='container5-child1' >
                    <h1 className='contact-title-head'>Contact</h1>
                </div>
                <div className='container5-child2'>
                    <ContactComponent />

                </div>
            </div> */}
            <FooterComponent />

        </div >

    );
};

export default HomeComponent;